import { makeAutoObservable, runInAction, autorun } from "mobx";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { Transaction, TransactionStream } from "xrpl";
import { isObject, isString, last } from "lodash";

import {
  IOffer,
  ICurrency,
  Period,
  IExchange,
  IBaseCoinMeta,
  IOrder,
  IAsset,
} from "@/types";
import { getCoinsApi } from "@/helpers/apiActions";
import { formatAlterPeriod } from "@/helpers/formatter";
import { formatNumber } from "@/helpers/formatter";

import RootStore from "./index";
import {
  defaultCoins,
  offerMode,
  tabsModeEnum,
  TradeModeEnum,
  TradeOrderBuySellEnum,
  TradeOrderModeEnum,
} from "./data/dexData";

export default class DexStore {
  public rootStore: RootStore;
  public coins: ICurrency[] = [];
  public sellOffers: IOffer[] = [];
  public buyOffers: IOffer[] = [];
  public baseCoinMeta?: IBaseCoinMeta;
  public baseCoin?: ICurrency;
  public quoteCoin?: ICurrency;
  public isBaseCoinTrustlineActive: boolean = false;
  public offerSpread: string = "0";
  public chartInterval: Period = "1day";
  public exchanges: IExchange[] = [];
  public newExchange?: IExchange;
  public isExchangesDataError: boolean = false;
  public tickTime: number = 10000;
  public price: number = 0;
  public tabsMode: number = tabsModeEnum.trade;
  public tradeMode: number = TradeModeEnum.swap;
  public tradeOrderMode: number = TradeOrderModeEnum.limit;
  public tradeOrderBuySellMode: number = TradeOrderBuySellEnum.buy;
  public tradeOrderAmount?: number;
  public tradeOrderMarketTotal: number = 0;
  public isTradeOrderMarketTotalLoading: boolean = false;
  public isTradeOrderSubmitting: boolean = false;
  public tradeOrderLimitTotal: number = 0;
  public tradeOrderPricePerToken?: number;
  public tradeOrderRange: number = 0;
  public isBalancesLoading: boolean = false;
  public isChartLoading: boolean = false;
  public isSellOffersLoading: boolean = false;
  public isBuyOffersLoading: boolean = false;
  public isMetaDataLoading: boolean = false;
  public isPriceLoading: boolean = false;
  public isOpenOrdersLoading: boolean = false;
  public isHistoryOrdersLoading: boolean = false;
  public sellOffersTotalVolumes?: {
    baseTotalVolume: number;
    quoteTotalVolume: number;
  };
  public buyOffersTotalVolumes?: {
    baseTotalVolume: number;
    quoteTotalVolume: number;
  };
  public openOrders: IOrder[] = [];
  public historyOrders: IOrder[] = [];
  public rateUsdXrp: number = 0;

  private isBuyOfferFetching?: boolean;
  private isSellOfferFetching?: boolean;
  private exchangeSubscribe: any;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;

    autorun(() => this.setOfferSpread());
    // autorun(() => this.getBaseCoinMeta());
  }

  async init() {
    if (!this.baseCoin || !this.quoteCoin) return;
    // console.log(
    //   this.baseCoin.ticker,
    //   this.baseCoin.isMeme,
    //   "this.baseCoin.ticker"
    // );
    this.getUsdXrpRate();
    this.getBalances();
    this.getOrderBook();
    this.getExchanges();
    this.getBaseCoinMeta();
    await this.getMemeCoinInfo(this.baseCoin.ticker, this.baseCoin.issuer);
  }

  async getCoins(isFetch?: boolean) {
    if (!isFetch && this.coins.length !== 0) return this.coins;

    const result: { isError: boolean; coins: any[] } =
      this.coins.length > 2 && !isFetch
        ? { coins: this.coins, isError: false }
        : await getCoinsApi({
            account: this.rootStore.accountStore.account ?? undefined,
          });

    const coins: ICurrency[] = result.coins
      ? result.coins
      : [defaultCoins.oxp, defaultCoins.xpunk];

    this.rootStore.swapStore.setAssets(coins);
    runInAction(() => {
      this.coins = coins;
    });
    // this.coins = coins;
  }

  async getUsdXrpRate() {
    try {
      const res = await axios.get(`/api/coins/get-usd-rate`);

      if (!res.data) {
        return {
          isError: true,
          message: "Unable to get usd rate.",
        };
      }

      runInAction(() => {
        this.rateUsdXrp = res.data?.rate;
      });
    } catch (err) {
      return {
        isError: true,
        message: "Unable to get usd rate.",
      };
    }
  }

  async setDefaultPair(
    baseCoinName: string,
    baseIssuer: string,
    quoteCoinName: string
  ) {
    this.isBalancesLoading = true;
    this.isSellOffersLoading = true;
    this.isBuyOffersLoading = true;
    this.isChartLoading = true;
    this.isMetaDataLoading = true;
    this.isPriceLoading = true;
    this.price = 0;
    this.tradeOrderAmount = 0;
    this.baseCoinMeta = undefined;
    this.exchanges = [];
    this.newExchange = undefined;
    this.isExchangesDataError = false;

    // console.log(this.coins.length, "this.coins");
    if (this.coins.length === 0) {
      const staticBaseCoin = {
        ticker: baseCoinName,
        issuer: baseIssuer,
        currencyHex:
          baseCoinName.length > 3
            ? this.convertStringToHex(baseCoinName)
            : baseCoinName,
        trustline: `https://xumm.community/?issuer=${baseIssuer}&limit=250000000`,
      };
      const staticQuoteCoin = defaultCoins.xrp;
      this.setBaseCoin(staticBaseCoin);
      this.setQuoteCoin(staticQuoteCoin);
      this.init();

      // const coins = (await this.getCoins()) || [];
      await this.getCoins(true);
    } else {
      const baseCoin =
        this.coins.find(
          (coin) => coin.ticker === baseCoinName && coin.issuer === baseIssuer
        ) ?? defaultCoins.oxp;
      const quoteCoin =
        this.coins.find((coin) => coin.ticker === quoteCoinName) ??
        defaultCoins.xrp;

      this.setBaseCoin(baseCoin);
      this.setQuoteCoin(quoteCoin);
      this.init();
    }
  }

  setBaseCoin(coin: ICurrency) {
    // if (coin.ticker === this?.baseCoin?.ticker) return;
    const asset: IAsset = {
      currencyHex: coin.currencyHex,
      issuer: coin.issuer,
      ticker: coin.ticker,
      trustline: coin.trustline,
      key: coin.ticker,
      name: coin.ticker,
      logo: "",
    };

    this.rootStore.swapStore.setReceiveAsset(asset);

    this.baseCoin = coin;
  }

  setQuoteCoin(coin: ICurrency) {
    // if (coin.ticker === this?.quoteCoin?.ticker) return;
    const asset: IAsset = {
      currencyHex: coin.currencyHex,
      issuer: coin.issuer,
      ticker: coin.ticker,
      trustline: coin.trustline,
      key: coin.ticker,
      name: coin.ticker,
      logo: "",
    };
    this.rootStore.swapStore.setSendAsset(asset);

    this.quoteCoin = coin;
  }

  getBalances() {
    this.rootStore.accountStore.getBalances();
  }

  getOrderBook() {
    this.getBuyOffers();
    this.getSellOffers();
  }

  async getBuyOffers() {
    if (!this.baseCoin || !this.quoteCoin || this.isBuyOfferFetching) return;

    this.isBuyOfferFetching = true;

    const results = await this.rootStore.xrplStore.getOffer(
      this.baseCoin,
      this.quoteCoin,
      offerMode.buy,
      200
    );

    const buyOffers: IOffer[] = results
      .map((offer) => {
        // @ts-ignore
        let takerGetsAmount = offer.TakerGets?.value
          ? // @ts-ignore
            Number(offer.TakerGets.value)
          : Number(offer.TakerGets) / 1_000_000;

        if (offer.taker_gets_funded) {
          takerGetsAmount = isString(offer.taker_gets_funded)
            ? Number(offer.taker_gets_funded) / 1_000_000
            : Number(offer.taker_gets_funded.value);
        }

        // @ts-ignore
        let takerPaysAmount = offer.TakerPays?.value
          ? // @ts-ignore
            Number(offer.TakerPays.value)
          : Number(offer.TakerPays) / 1_000_000;

        if (offer.taker_pays_funded) {
          takerPaysAmount = isString(offer.taker_pays_funded)
            ? Number(offer.taker_pays_funded) / 1_000_000
            : Number(offer.taker_pays_funded.value);
        }

        const price = takerGetsAmount / takerPaysAmount;

        return {
          price: price,
          volume: takerPaysAmount,
          total: takerGetsAmount,
          sequence: offer.Sequence,
          owner: offer.Account,
        };
      })
      .filter((p) => p.volume > 0);

    const buyOffersTotalVolumes = buyOffers.reduce<{
      baseTotalVolume: number;
      quoteTotalVolume: number;
    }>(
      (p, c) => {
        const baseTotalVolume = p.baseTotalVolume + c.volume;
        const quoteTotalVolume = p.quoteTotalVolume + c.total;

        return { baseTotalVolume, quoteTotalVolume };
      },
      { baseTotalVolume: 0, quoteTotalVolume: 0 }
    );

    runInAction(() => {
      this.buyOffers = buyOffers;
      this.buyOffersTotalVolumes = buyOffersTotalVolumes;
      this.isBuyOfferFetching = false;
      this.isBuyOffersLoading = false;
    });
  }

  async getSellOffers() {
    if (!this.baseCoin || !this.quoteCoin || this.isSellOfferFetching) return;

    this.isSellOfferFetching = true;

    const results = await this.rootStore.xrplStore.getOffer(
      this.baseCoin,
      this.quoteCoin,
      offerMode.sell,
      200
    );

    const sellOffers: IOffer[] = results
      .map((offer) => {
        // @ts-ignore
        let takerGetsAmount = offer.TakerGets?.value
          ? // @ts-ignore
            Number(offer.TakerGets.value)
          : Number(offer.TakerGets) / 1_000_000;

        if (offer.taker_gets_funded) {
          takerGetsAmount = isString(offer.taker_gets_funded)
            ? Number(offer.taker_gets_funded) / 1_000_000
            : Number(offer.taker_gets_funded.value);
        }

        // @ts-ignore
        let takerPaysAmount = offer.TakerPays?.value
          ? // @ts-ignore
            Number(offer.TakerPays.value)
          : Number(offer.TakerPays) / 1_000_000;
        if (offer.taker_pays_funded) {
          takerPaysAmount = isString(offer.taker_pays_funded)
            ? Number(offer.taker_pays_funded) / 1_000_000
            : Number(offer.taker_pays_funded.value);
        }

        const price = takerPaysAmount / takerGetsAmount;

        return {
          price: price,
          volume: takerGetsAmount,
          total: takerPaysAmount,
          sequence: offer.Sequence,
          owner: offer.Account,
        };
      })
      .filter((p) => p.volume > 0);

    const sellOffersTotalVolumes = sellOffers.reduce<{
      baseTotalVolume: number;
      quoteTotalVolume: number;
    }>(
      (p, c) => {
        const baseTotalVolume = p.baseTotalVolume + c.volume;
        const quoteTotalVolume = p.quoteTotalVolume + c.total;

        return { baseTotalVolume, quoteTotalVolume };
      },
      { baseTotalVolume: 0, quoteTotalVolume: 0 }
    );

    runInAction(() => {
      this.sellOffers = sellOffers;
      this.sellOffersTotalVolumes = sellOffersTotalVolumes;
      this.isSellOfferFetching = false;
      this.isSellOffersLoading = false;
    });
  }

  setOfferSpread() {
    if (this.buyOffers.length === 0 || this.sellOffers.length === 0) return;

    runInAction(() => {
      this.offerSpread = formatNumber(
        this.sellOffers[0].price - this.buyOffers[0].price,
        4
      );
    });
  }

  async getExchanges() {
    if (!this.baseCoin || !this.quoteCoin) return;

    const result = await this.getChartData(5);

    runInAction(() => {
      if (result?.data?.exchanges && result?.data?.exchanges?.length !== 0) {
        const cleanedData = this.cleanTradingData(result.data.exchanges);
        // console.log(cleanedData, "CLEAN DATA");

        this.exchanges = cleanedData || [];
        // console.log(result.data.exchanges, "exchanges");

        this.price =
          last<any>(result.data.exchanges ?? [])?.close ?? this.price;
        this.isExchangesDataError = false;
        this.setExchangeSubscribe();
      } else {
        this.price = 0;
        this.isExchangesDataError = true;
        this.setExchangeUnSubscribe();
      }
      this.isChartLoading = false;
      this.isPriceLoading = false;
    });
  }

  async setExchangeSubscribe() {
    this.setExchangeUnSubscribe();

    this.exchangeSubscribe = setInterval(async () => {
      this.getExchangeUpdate();
    }, this.tickTime);
  }

  setExchangeUnSubscribe() {
    if (this.exchangeSubscribe) {
      clearInterval(this.exchangeSubscribe);
    }
  }

  async getExchangeUpdate() {
    if (!this.exchanges || this.exchanges.length === 0) return;

    const result = await this.getChartData(1, false);
    // console.log(result, "getExchangeUpdate");
    if (result?.data?.exchanges) {
      const cleanedData = this.cleanTradingData(result.data.exchanges);

      runInAction(() => {
        this.newExchange = cleanedData[0];
        this.price =
          last<any>(result.data.exchanges ?? [])?.close ?? this.price;
      });
    }
  }

  async getChartData(limit: number = 500, setError = true) {
    // console.log("getChartData");
    if (!this.baseCoin || !this.quoteCoin) return [];

    let result: any = {};

    try {
      result = await axios.post(`/api/coins/get-chart-data`, {
        baseCoin: {
          currencyHex: this.baseCoin.currencyHex,
          issuer: this.baseCoin.issuer,
        },
        quoteCoin: {
          currencyHex: this.quoteCoin.currencyHex,
          issuer: this.quoteCoin.issuer,
        },
        descending: true,
        limit,
        interval: this.chartInterval,
      });
    } catch (err) {
      Sentry.captureException(err);
      result = await this.getAlterChartData(limit);
      if (
        setError &&
        (!result?.data?.exchange || result?.data?.exchange?.length === 0)
      ) {
        runInAction(() => {
          this.isExchangesDataError = true;
        });
      }
    }

    return result;
  }

  async getMemeCoinInfo(baseCoinName: string, baseIssuer: string) {
    // Ensure the required coin data is available
    if (!this.baseCoin || !this.quoteCoin) return;

    // console.log("getMemeCoinInfo", baseCoinName, baseIssuer);

    try {
      const url = `/api/coins/get-meme-coin-info?ticker=${baseCoinName}&issuer=${baseIssuer}`;
      const result: any = await axios.get(url);

      // console.log(result.data?.memecoin, "getMemeCoinInfo");

      runInAction(() => {
        this.baseCoin = {
          ...this.baseCoin,
          marketcap_fl: result.data?.memecoin?.market_cap,
          vol24h_fl: result.data?.memecoin?.volume_24h ?? 0,
          currencyHex: this.baseCoin?.currencyHex ?? "",
          issuer: this.baseCoin?.issuer ?? "",
          ticker: this.baseCoin?.ticker ?? "",
          trustline: this.baseCoin?.trustline ?? "",
          usd: this.baseCoin?.usd ?? 0,
          pro24h: this.baseCoin?.pro24h ?? 0,
          isMeme: this.baseCoin?.isMeme ?? false,
        };
      });
    } catch (err) {
      console.error("Error fetching meme coin info:", err);
    }
  }

  async getAlterChartData(limit: number = 100) {
    // console.log("getAlterChartData");
    try {
      const result = await axios.post("/api/coins/get-alter-chart", {
        currencyHex: this.baseCoin?.currencyHex,
        issuer: this.baseCoin?.issuer,
        counterCurrencyHex: this.quoteCoin?.currencyHex,
        counterCurrencyIssuer: this.quoteCoin?.issuer,
        limit: limit,
        period: formatAlterPeriod(this.chartInterval),
        refresh: true,
      });

      return result;
    } catch (err) {
      return [];
    }
  }

  async getBaseCoinMeta() {
    // console.log("getBaseCoinMeta");
    if (!this.baseCoin || !this.quoteCoin) return;

    // const startTime = new Date().getTime();
    try {
      const result: any = await axios.post(`/api/coins/get-meta`, {
        currencyHex: this.baseCoin.currencyHex,
        issuer: this.baseCoin.issuer,
        counterCurrencyHex: this.quoteCoin?.currencyHex,
        counterCurrencyIssuer: this.quoteCoin?.issuer,
      });
      // const endTime = new Date().getTime();
      // console.log("getBaseCoinMeta-time", (endTime - startTime) / 1000);
      // console.log("getBaseCoinMeta-result", result.data);

      runInAction(() => {
        this.baseCoinMeta = result.data;
        this.isMetaDataLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.baseCoinMeta = {
          dailyCloseYesterday: 0,
          high: "0",
          low: "0",
          volume: "0",
        };
        this.isMetaDataLoading = false;
      });
    }
  }

  setChartInterval(period: Period) {
    this.chartInterval = period;
    this.isChartLoading = true;
    this.isExchangesDataError = false;

    this.setExchangeUnSubscribe();
    this.getExchanges();
  }

  setTabsMode(mode: number) {
    this.tabsMode = mode;
  }

  setTradeMode(mode: number) {
    this.tradeMode = mode;
    // this.setTradeOrderCreateOfferLimitTotal();
    this.setTradeOrderMarketTotal();
  }

  setTradeOrderMode(mode: number) {
    this.tradeOrderMode = mode;
    this.setTradeOrderLimitTotal();
    // this.setTradeOrderMarketTotal();
  }

  setTradeOrderBuySellMode(mode: number) {
    this.tradeOrderBuySellMode = mode;
    this.setTradeOrderLimitTotal();
    // this.setTradeOrderMarketTotal();
  }

  setPercentageAmount(value: string) {
    const amount =
      this.tradeMode === TradeModeEnum.swap
        ? // ? this.rootStore.accountStore.quoteBalance * Number(value)
          this.rootStore.swapStore.sendAssetBalance! * Number(value)
        : this.tradeOrderBuySellMode === TradeOrderBuySellEnum.sell
        ? this.rootStore.accountStore.baseBalance * Number(value)
        : this.rootStore.accountStore.quoteBalance * Number(value);

    if (this.tradeMode === TradeModeEnum.swap) {
      this.setTradeOrderAmount(amount);
      return;
    }

    if (this.tradeOrderMode !== TradeOrderModeEnum.market) {
      if (this.tradeMode === TradeModeEnum.swap) {
        const limitTradeAmount = this.tradeOrderPricePerToken
          ? amount / this.tradeOrderPricePerToken
          : 0;
        this.setTradeOrderAmount(limitTradeAmount);
        return;
      } else if (this.tradeOrderBuySellMode === TradeOrderBuySellEnum.buy) {
        // const tradeMargin = 0.98;
        const limitTradeAmount = this.tradeOrderPricePerToken
          ? (this.rootStore.accountStore.quoteBalance /
              this.tradeOrderPricePerToken) *
            Number(value)
          : 0;
        this.setTradeOrderAmount(limitTradeAmount);
        return;
      } else {
        this.setTradeOrderAmount(amount);
        return;
      }
    }
  }

  setTradeOrderAmount(amount?: number) {
    if (amount === undefined) return (this.tradeOrderAmount = undefined);

    this.tradeOrderAmount =
      this.tradeMode === TradeModeEnum.swap
        ? amount
        : this.rootStore.accountStore?.baseBalance &&
          this.rootStore.accountStore.baseBalance < amount &&
          this.tradeOrderBuySellMode === TradeOrderBuySellEnum.sell
        ? this.rootStore.accountStore.quoteBalance
        : amount;

    this.setTradeOrderLimitTotal();
  }

  setTradeOrderPricePerToken(price: number) {
    this.tradeOrderPricePerToken = price;

    this.setTradeOrderLimitTotal();
  }

  async setTradeOrderMarketTotal() {
    if (
      this.buyOffers.length === 0 ||
      this.sellOffers.length === 0 ||
      !this.tradeOrderAmount ||
      !this.sellOffersTotalVolumes ||
      !this.buyOffersTotalVolumes ||
      this.tradeOrderMode !== TradeOrderModeEnum.market
    ) {
      return (this.tradeOrderMarketTotal = 0);
    }

    const offersTotalVolumes =
      this.tradeMode === TradeModeEnum.swap
        ? this.sellOffersTotalVolumes
        : this.buyOffersTotalVolumes;

    // if (this.tradeOrderAmount > offersTotalVolumes?.baseTotalVolume) {
    //   this.getTradeOrderMarketTotal();
    //   return;
    // }

    const offers =
      this.tradeMode === TradeModeEnum.swap ? this.sellOffers : this.buyOffers;

    let spentAmount: number = 0;
    let currentVolume: number = 0;

    for (let i = 0; i <= offers.length - 1; i++) {
      const volumeLeft =
        currentVolume + offers[i].volume - this.tradeOrderAmount;

      if (volumeLeft > 0) {
        currentVolume = this.tradeOrderAmount;
        spentAmount += (offers[i].volume - volumeLeft) * offers[i].price;

        break;
      }

      currentVolume += offers[i].volume;
      spentAmount += offers[i].volume * offers[i].price;
    }

    const priceRange =
      this.tradeMode === TradeModeEnum.swap
        ? 1 + this.tradeOrderRange
        : 1 - this.tradeOrderRange;

    this.tradeOrderMarketTotal = spentAmount * priceRange;
    this.tradeOrderAmount =
      currentVolume < this.tradeOrderAmount
        ? currentVolume
        : this.tradeOrderAmount;
  }

  getAmountFromOrderBook({
    quoteAmount,
    baseAmount,
  }: {
    quoteAmount?: number;
    baseAmount?: number;
  }) {
    if (quoteAmount) {
      const amount = quoteAmount;
      const offers =
        this.tradeMode === TradeModeEnum.swap
          ? this.sellOffers
          : this.buyOffers;

      let spentAmount: number = 0;
      let currentVolume: number = 0;

      for (let i = 0; i <= offers.length - 1; i++) {
        const volumeLeft = currentVolume + offers[i].total - amount;

        if (volumeLeft > 0) {
          currentVolume = amount;
          spentAmount += (offers[i].total - volumeLeft) / offers[i].price;

          break;
        }

        currentVolume += offers[i].total;
        spentAmount += offers[i].total / offers[i].price;
      }

      return {
        currentVolume,
        spentAmount,
      };
    }

    if (baseAmount) {
      const amount = baseAmount;
      const offers =
        this.tradeMode === TradeModeEnum.swap
          ? this.sellOffers
          : this.buyOffers;

      let spentAmount: number = 0;
      let currentVolume: number = 0;

      for (let i = 0; i <= offers.length - 1; i++) {
        const volumeLeft = currentVolume + offers[i].volume - amount;

        if (volumeLeft > 0) {
          currentVolume = amount;
          spentAmount += (offers[i].volume - volumeLeft) * offers[i].price;

          break;
        }

        currentVolume += offers[i].volume;
        spentAmount += offers[i].volume * offers[i].price;
      }

      return {
        currentVolume,
        spentAmount,
      };
    }

    return {
      currentVolume: 0,
      spentAmount: 0,
    };
  }

  setTradeOrderLimitTotal() {
    if (
      !this.tradeOrderPricePerToken ||
      !this.tradeOrderAmount ||
      this.tradeOrderMode !== TradeOrderModeEnum.limit
    )
      return (this.tradeOrderLimitTotal = 0);

    this.tradeOrderLimitTotal =
      this.tradeOrderPricePerToken * this.tradeOrderAmount;
  }

  async getTradeOrderMarketTotal() {
    if (
      !this.tradeOrderAmount ||
      !this.baseCoin ||
      !this.quoteCoin ||
      !this.rootStore.accountStore.account
    )
      return;

    this.isTradeOrderMarketTotalLoading = true;

    const priceRange =
      this.tradeMode === TradeModeEnum.swap
        ? 1 + this.tradeOrderRange
        : 1 - this.tradeOrderRange;

    const {
      receivedAmount,
      spendAmount,
    }: { receivedAmount?: number; spendAmount?: number } =
      await this.getTradeOrderMarketTotalRequest();

    if (receivedAmount && spendAmount) {
      runInAction(() => {
        this.isTradeOrderMarketTotalLoading = false;
        this.tradeOrderMarketTotal = receivedAmount * priceRange;
        this.tradeOrderAmount = spendAmount;
      });
    }
  }

  private async getTradeOrderMarketTotalRequest() {
    if (
      !this.tradeOrderAmount ||
      !this.baseCoin ||
      !this.quoteCoin ||
      !this.rootStore.accountStore.account
    )
      return {};

    if (this.tradeMode === TradeModeEnum.limit) {
      const result: any = await this.rootStore.xrplStore.getPathFind({
        sendCoin: this.baseCoin,
        receiveCoin: this.quoteCoin,
        account: this.rootStore.accountStore.account,
        sourceAmount: this.tradeOrderAmount,
        destinationAmount: -1,
      });

      const destinationAmount =
        result?.result?.alternatives?.[0]?.destination_amount ?? 0;
      const sourceAmount =
        result?.result?.alternatives?.[0]?.source_amount?.value ??
        this.tradeOrderAmount;

      return {
        receivedAmount:
          this.quoteCoin.currencyHex === "XRP"
            ? destinationAmount / 1_000_000
            : destinationAmount,
        spendAmount: sourceAmount,
      };
    }

    if (this.tradeMode === TradeModeEnum.swap) {
      const result: any = await this.rootStore.xrplStore.getPathFind({
        sendCoin: this.quoteCoin,
        receiveCoin: this.baseCoin,
        account: this.rootStore.accountStore.account,
        destinationAmount: this.tradeOrderAmount,
      });

      const destinationAmount =
        result?.result?.destination_amount?.value ?? this.tradeOrderAmount;
      const sourceAmount =
        result?.result?.alternatives?.[0]?.source_amount ?? 0;

      return {
        receivedAmount:
          this.quoteCoin.currencyHex === "XRP"
            ? sourceAmount / 1_000_000
            : sourceAmount,
        spendAmount: destinationAmount,
      };
    }

    return {};
  }

  async createOffer() {
    const { account, userToken } = this.rootStore.accountStore;

    if (!account || !userToken || !this.baseCoin || !this.tradeOrderAmount)
      return;
    this.isTradeOrderSubmitting = true;

    const mode =
      this.tradeOrderBuySellMode === TradeOrderBuySellEnum.buy ? "buy" : "sell";
    // let counterValue = this.tradeOrderLimitTotal;

    // if (this.tradeOrderMode === TradeOrderModeEnum.market) {
    //   const { receivedAmount } = await this.getTradeOrderMarketTotalRequest();
    //   if (receivedAmount) counterValue = receivedAmount;
    // }

    // if (this.quoteCoin?.ticker === "XRP") {
    //   counterValue = counterValue * 1_000_000;
    // }

    // if (this.tradeOrderMode === TradeOrderModeEnum.market)
    //   counterValue *= this.tradeMode === TradeModeEnum.swap ? 1.01 : 0.99;

    // counterValue = Math.floor(counterValue);

    // const rateRange = 0.98;

    const value =
      this.baseCoin?.currencyHex === "XRP"
        ? Math.floor(this.tradeOrderAmount * 1_000_000)
        : this.tradeOrderAmount;

    const counterValue =
      this.quoteCoin?.currencyHex === "XRP"
        ? Math.floor(this.tradeOrderLimitTotal * 1_000_000)
        : this.tradeOrderLimitTotal;

    const offer = {
      mode: mode,
      marketOrder: this.tradeOrderMode === TradeOrderModeEnum.market,
      account: account,
      userToken: userToken,
      currency: this.baseCoin.currencyHex,
      issuer: this.baseCoin.issuer === "XRPL" ? "" : this.baseCoin.issuer,
      value: value,
      counterCurrency: this.quoteCoin?.currencyHex,
      counterCurrencyIssuer:
        this.quoteCoin?.issuer === "XRPL" ? "" : this.quoteCoin?.issuer,
      counterValue: counterValue,
    };

    await this.rootStore.walletStore.createOffer(offer);

    this.rootStore.accountStore.updateCoinsBalance();

    runInAction(() => {
      this.isTradeOrderSubmitting = false;
    });
  }

  async getAccountOrdersHistory() {
    if (
      !this.rootStore.accountStore.account ||
      !this.baseCoin ||
      !this.quoteCoin
    )
      return;

    this.isHistoryOrdersLoading = true;
    const account = this.rootStore.accountStore.account;

    const response = await this.rootStore.xrplStore.getAccountTx(account);
    if (!response?.result?.transactions) return [];

    const history: IOrder[] = [];

    response.result.transactions.forEach((t) => {
      const tx = t.tx_json ?? t.tx;
      if (
        isObject(t.meta) &&
        t.meta.TransactionResult === "tesSUCCESS" &&
        !t.meta.AffectedNodes.find(
          (node: any) => node?.CreatedNode?.LedgerEntryType === "Offer"
        ) &&
        tx &&
        tx.TransactionType === "OfferCreate" &&
        (tx.TakerGets || tx.TakerPays) &&
        ((isObject(tx.TakerGets) &&
          tx.TakerGets.currency === this.baseCoin?.currencyHex &&
          tx.TakerGets.issuer === this.baseCoin?.issuer &&
          typeof tx.TakerPays === "string") ||
          (isObject(tx.TakerPays) &&
            tx.TakerPays.currency === this.baseCoin?.currencyHex &&
            tx.TakerPays.issuer === this.baseCoin?.issuer &&
            typeof tx.TakerGets === "string"))
      ) {
        const data: IOrder = {};
        const nodes = t.meta.AffectedNodes;

        const accountRootNode: any = nodes.find(
          (item: any) =>
            item?.ModifiedNode?.LedgerEntryType === "AccountRoot" &&
            item?.ModifiedNode?.FinalFields?.Account === account
        );

        const rippleStateNode: any = nodes.find(
          (item: any) =>
            (item?.ModifiedNode?.LedgerEntryType === "RippleState" &&
              item?.ModifiedNode?.FinalFields?.Balance.currency ===
                this.baseCoin?.currencyHex &&
              (item?.ModifiedNode?.FinalFields?.HighLimit.issuer ===
                this.baseCoin?.issuer ||
                item?.ModifiedNode?.FinalFields?.LowLimit.issuer ===
                  this.baseCoin?.issuer ||
                item?.ModifiedNode?.FinalFields?.HighLimit.issuer === account ||
                item?.ModifiedNode?.FinalFields?.LowLimit.issuer === account ||
                item?.CreatedNode?.NewFields?.HighLimit.issuer === account)) ||
            (item?.CreatedNode?.LedgerEntryType === "RippleState" &&
              item?.CreatedNode?.NewFields?.Balance.currency ===
                this.baseCoin?.currencyHex &&
              (item?.CreatedNode?.NewFields?.HighLimit.issuer === account ||
                item?.CreatedNode?.NewFields?.LowLimit.issuer === account))
        );

        const baseCoinBalanceDiff = rippleStateNode?.ModifiedNode
          ? Number(rippleStateNode?.ModifiedNode?.FinalFields?.Balance.value) -
            Number(rippleStateNode?.ModifiedNode?.PreviousFields?.Balance.value)
          : rippleStateNode?.CreatedNode
          ? -Number(rippleStateNode?.CreatedNode?.NewFields.Balance.value)
          : 0;

        const quoteCoinBalanceDiff = !accountRootNode
          ? 0
          : accountRootNode?.ModifiedNode?.FinalFields?.Balance -
            accountRootNode?.ModifiedNode?.PreviousFields?.Balance;

        data.hash = t?.hash;
        data.mode = tx.Flags === 655360 ? "SWAP" : "LIMIT";
        // data.operation = quoteCoinBalanceDiff > 0 ? "SELL" : "BUY";
        data.operation = typeof tx.TakerGets === "string" ? "BUY" : "SELL";

        data.type =
          (tx.Flags === 131072 || tx.Flags === 655360) && tx.Account === account
            ? "Market"
            : "Limit";
        data.date = tx?.date ? tx?.date + 946684800 : 946684800;
        data.volume = formatNumber(Math.abs(baseCoinBalanceDiff), 2, 2, false);

        data.total =
          quoteCoinBalanceDiff > 0
            ? "+" + formatNumber(quoteCoinBalanceDiff, 2, 2, true)
            : formatNumber(quoteCoinBalanceDiff, 2, 2, true);
        data.status =
          t.meta.TransactionResult === "tesSUCCESS" ? "Successful" : "Cancel";

        // data.status =
        //   !rippleStateNode || !accountRootNode ? "Cancel" : "Success";

        // data.mode = typeof tx.TakerGets === "string" ? "SWAP" : "LIMIT";

        // if (tx.Account === account) {
        //   data.mode = typeof tx.TakerGets === "string" ? "SWAP" : "LIMIT";
        // } else {
        //   data.mode = baseCoinBalanceDiff > 0 ? "SWAP" : "LIMIT";
        // }

        if (
          (tx.Flags === 131072 || tx.Flags === 655360) &&
          tx.Account === account
        ) {
          data.status =
            t.meta.TransactionResult === "tesSUCCESS" ? "Successful" : "Cancel";
        }

        history.push(data);
      }
    });

    runInAction(() => {
      this.historyOrders = history;
      this.isHistoryOrdersLoading = false;
    });
  }

  async getAccountOffers() {
    if (
      !this.rootStore.accountStore.account ||
      !this.baseCoin ||
      !this.quoteCoin
    )
      return;

    this.isOpenOrdersLoading = true;

    const response = await this.rootStore.xrplStore.getAccountOffers(
      this.rootStore.accountStore.account
    );

    if (!response?.result?.offers) return [];

    const offers: IOrder[] = [];

    // console.log(response.result, "offers");
    response.result.offers.forEach((o) => {
      if (o.taker_gets && o.taker_pays) {
        const data: IOrder = {};
        if (
          isObject(o.taker_gets) &&
          o.taker_gets.currency === this.baseCoin?.currencyHex &&
          o.taker_gets.issuer === this.baseCoin?.issuer
        ) {
          // console.log("SELL");
          const isXRP =
            !isObject(o.taker_pays) || o.taker_pays.currency === "XRP";
          const paysValue = parseFloat(
            isObject(o.taker_pays) ? o.taker_pays.value : o.taker_pays
          );
          const getsValue = parseFloat(o.taker_gets.value);
          data.mode = "SELL";
          data.price = formatNumber(paysValue / getsValue, 2, 6, isXRP);
          data.volume = formatNumber(getsValue, 2, 7);
          data.total = formatNumber(paysValue, 2, 6, isXRP);
          data.sequence = o.seq;

          offers.push(data);
        } else if (
          isObject(o.taker_pays) &&
          o.taker_pays.currency === this.baseCoin?.currencyHex &&
          o.taker_pays.issuer === this.baseCoin?.issuer
        ) {
          // console.log("BUY");

          const isXRP =
            !isObject(o.taker_gets) || o.taker_gets.currency === "XRP";
          const paysValue = parseFloat(o.taker_pays.value);
          const getsValue = parseFloat(
            isObject(o.taker_gets) ? o.taker_gets.value : o.taker_gets
          );
          data.mode = "BUY";
          data.price = formatNumber(getsValue / paysValue, 2, 6, isXRP);
          data.volume = formatNumber(paysValue, 2, 7);
          data.total = formatNumber(getsValue, 2, 6, isXRP);
          data.sequence = o.seq;

          offers.push(data);
        }
      }
    });

    runInAction(() => {
      this.openOrders = offers;
      this.isOpenOrdersLoading = false;
    });
  }

  cancelOrder(order: IOrder) {
    if (
      !this.rootStore.accountStore.account ||
      !this.rootStore.accountStore.userToken ||
      !order
    )
      return;

    this.rootStore.walletStore.cancelOffer(order);
    this.rootStore.accountStore.updateCoinsBalance();
  }

  async transactionSubscribeUpdate(tx: TransactionStream) {
    if (!tx?.transaction) return;

    if (
      tx.transaction?.TransactionType === "OfferCreate" ||
      tx.transaction?.TransactionType === "OfferCancel"
    ) {
      this.getOrderBook();
    }
  }

  isAnomalous(min: number, value: number) {
    return value > 3 * min;
  }

  cleanTradingData(data: any) {
    if (data.length === 0) return data;
    const cleanedData = [data[0]]; // Start with the first entry

    for (let i = 1; i < data.length; i++) {
      const previous = data[i - 1];
      const current = data[i];

      // current.low = this.isAnomalous(previous.low, current.low)
      //   ? previous.low
      //   : current.low;
      current.high = this.isAnomalous(current.low, current.high)
        ? current.low
        : current.high;
      current.open = this.isAnomalous(current.low, current.open)
        ? current.low
        : current.open;
      current.close = this.isAnomalous(current.low, current.close)
        ? current.low
        : current.close;
      current.vwap = this.isAnomalous(current.low, current.vwap)
        ? current.low
        : current.vwap;
      cleanedData.push(current);
    }

    return cleanedData;
  }

  convertStringToHex(text: string) {
    const hexRepresentation = [...text]
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("");
    const fullHexRepresentation = hexRepresentation
      .padEnd(40, "0")
      .toUpperCase();
    return fullHexRepresentation;
  }
}
