import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Link as MuiLink,
  TextField,
  Theme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import close from "@/assets/images/closeIcon.svg";

import { useAppStore } from "@/stores";

import CoinPair from "./CoinPair";
import { ICurrency } from "@/types";

const mainCoins = [
  "XRP",
  // "OXP"
];

interface CoinPairsProps {}

const CoinPairs: React.FC<CoinPairsProps> = observer(() => {
  const theme = useTheme();
  const {
    dexStore: { coins, baseCoin, quoteCoin, setBaseCoin, setQuoteCoin },
  } = useAppStore();
  const [isOpen, setIsOpen] = useState(false);
  const [coinMain, setCoinMain] = useState<string>(mainCoins[0]);
  const [partialCoins, setPartialCoins] = useState<ICurrency[]>([]);

  useEffect(() => {
    setPartialCoins(coins.slice(0, 100));
  }, [coins]);

  const _handleSelectChange = (
    baseCoinName: string,
    baseCoinIssuer: string,
    quoteCoinName: string
  ) => {
    const _baseCoin = coins.find(
      (c) => c.ticker === baseCoinName && c.issuer === baseCoinIssuer
    );
    const _quoteCoin =
      quoteCoinName === "XRP"
        ? { issuer: "", ticker: "XRP", currencyHex: "XRP", trustline: "" }
        : coins.find((c) => c.ticker === quoteCoinName);

    if (_baseCoin && _quoteCoin) {
      setBaseCoin(_baseCoin);
      setQuoteCoin(_quoteCoin);
    }
    setPartialCoins(coins.slice(0, 100));
    setIsOpen(false);
  };

  const _handleSearchChange = (e: any) => {
    const filterCoins = coins.filter((item) => {
      const searchPhrase = e.target.value.toLowerCase();
      if (item.ticker.toLowerCase() === coinMain.toLowerCase()) return false;
      if (searchPhrase && !item.ticker.toLowerCase().includes(searchPhrase))
        return false;

      return true;
    });
    setPartialCoins(filterCoins.slice(0, 100));
  };

  const _showPairsDialog = () => {
    setIsOpen(true);
  };

  const _handleDialogClose = () => {
    setPartialCoins(coins.slice(0, 100));
    setIsOpen(false);
  };

  // const _onSelectMainCoin = (item: string) => {
  //   setIsFavorites(false);
  //   setCoinMain(item);
  // };

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <DialogStyled
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        scroll={"paper"}
        // @ts-ignore
        PaperComponent="div"
        open={isOpen}
        onClose={_handleDialogClose}
        className="pairs-dialog"
        pt={-5}
      >
        <DialogTitle
          sx={{
            padding: 0,
            margin: "0 0 8px 0",
            color: "#ffffff",
          }}
        >
          <Typography sx={titleSx} mb={2}>
            Select Pairs
          </Typography>
          <Typography sx={textSx} mb={2}>
            Please select the pairs you wish to trade on the <span>bidds</span>{" "}
            DEX.
          </Typography>
          <Typography sx={subtitleSx}>Available Pairs</Typography>
          <TextField
            fullWidth
            placeholder="Search Pair"
            onChange={_handleSearchChange}
            className="pairs-search"
            sx={searchBoxSx}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="start">LUPA</InputAdornment>
            //   ),
            // }}
          />
          <Divider></Divider>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            maxHeight: "30vh",
            minHeight: "30vh",
            padding: "0px 8px",
            marginTop: "10px",
            color: "#ffffff",
            [theme.breakpoints.down("sm")]: {
              maxHeight: "100%",
            },
          })}
        >
          {partialCoins.map((c, index) => (
            <CoinPair
              quoteCoinTicker={coinMain}
              baseCoinTicker={c.ticker}
              baseCoinIssuer={c.issuer}
              baseCoinName={c.name_long}
              verified={c.verified}
              isMeme={c.isMeme}
              balance={c.balance}
              handleSelectChange={_handleSelectChange}
              key={"pair" + coinMain + c.ticker + c.currencyHex + index}
            />
          ))}
        </DialogContent>
        <Box
          onClick={_handleDialogClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "#ffffff",
            height: "28px",
            width: "28px",
            backgroundColor: "#000",
            borderRadius: "8px",
            border: "1px solid #25333C",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img className="close-icon" src={close} alt="close" />
        </Box>
      </DialogStyled>
      <Box
        sx={(theme) => ({
          background: "#161F25",
          borderRadius: { xs: "6.25px", md: "10px" },
          height: "60px",
          [theme.breakpoints.up("sm")]: {
            height: "72px",
          },
          py: 1.5,
          px: 3.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        })}
      >
        <Box
          sx={(theme) => ({ display: "flex", justifyContent: "space-between" })}
        >
          <Typography
            sx={(theme) => ({
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "14px",
              color: theme.palette.text.secondary,
              mr: "5px",
            })}
          >
            {baseCoin?.ticker}
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "14px",
              color: theme.palette.text.grayBorder,
            })}
          >
            / {quoteCoin?.ticker}
          </Typography>
        </Box>
        <Box
          onClick={_showPairsDialog}
          sx={{
            height: "32px",
            cursor: "pointer",
            background: "#11181D",
            padding: "10px 15px",
            borderRadius: "8px",
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              opacity: "0.8",
              transition: "opacity 0.3s ease-in-out",
            },
          }}
          display="flex"
          alignItems="center"
        >
          <Typography
            sx={(theme) => ({
              fontSize: "10px",
              fontWeight: 700,
              lineHeight: "10px",
              color: theme.palette.text.lightGray,
            })}
          >
            VIEW PAIRS
          </Typography>
          <ArrowDropDownIcon
            sx={(theme) => ({
              color: theme.palette.text.lightGray,
              // ml: 2,
            })}
          />
        </Box>
      </Box>
      {/* </Box> */}
    </>
  );
});

const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    opacity: "0.4",
  },
  "& .MuiDialog-scrollPaper": {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    opacity: "0.4",
  },
  "& .MuiDialog-paper": {
    // background: `linear-gradient(90deg, ${theme.palette.background.gradientStart}, ${theme.palette.background.gradientEnd})`,
    backgroundColor: "#161F25",
    borderRadius: "15px", // Default for non-phone sizes
    padding: "16px 16px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "5px", // Smaller radius for phones
    },
  },
  "& .MuiTypography-root": {
    color: theme.palette.text.modal,
  },
  "& .MuiDialogContent-root": {
    color: "#ffffff",
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      borderRadius: "24px",
      backgroundColor: theme.palette.background.modalBodySecondary,
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.background.modalBodySecondary,
    color: theme.palette.text.modal,
  },
}));

const searchBoxSx = (theme: Theme) => ({
  color: "#ffffff",
  borderRadius: "6.25px",
  border: "1px solid #25333C",
  marginTop: "16px",
  marginBottom: "16px",
  "& .MuiInputBase-root": {
    padding: "0px 0px",
  },
});

const titleSx = (theme: Theme) => ({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "28px",
});

const subtitleSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
});

const textSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  "& span": {
    color: theme.palette.text.bidds,
  },
});

export default CoinPairs;
