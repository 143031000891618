import { useAppStore } from "@/stores";
import Logo from "../../../assets/bidds/images/bidd-beta-desktop.svg";
// import LogoMobile from "../../../assets/bidds/images/bidd-beta-mobile.svg";
import LoginIcon from "../../../assets/bidds/images/login-icon.svg";
import Profile from "../../../assets/bidds/images/profile.jpg";
import DownArrow from "../../../assets/bidds/images/downArrow.svg";
import SignOut from "../../../assets/bidds/images/icon-log-out.svg";
import MobileMenuIcon from "../../../assets/bidds/images/mobileMenu.svg";
import TabMenuIcon from "../../../assets/bidds/images/tabMenu.svg";
import Close from "../../../assets/bidds/images/close.svg";

import WalletHeaderBalances from "../wallets/WalletHeaderBalances";
import Button from "@mui/material/Button";
import { observer } from "mobx-react";
import { useTheme, useMediaQuery, Box, Link } from "@mui/material";
import { MAIN_MENU_ITEMS, PAGE_LINKS } from "@/helpers/constants";
import { useEffect, useRef, useState } from "react";
import { Image } from "../image/Image";
import { LoadingButton } from "@mui/lab";

const MobileMenu = ({
  isMobileMenuActive,
  setIsMobileMenuActive,
  isAuthenticated,
}: {
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: any;
  isAuthenticated: boolean;
}) => {
  return (
    <div
      className={`top-nav__mobile-menu_icon ${
        isMobileMenuActive && "border"
      }  ${!isAuthenticated && "customLeftMargin"}`}
      onClick={() => setIsMobileMenuActive((isOpen: any) => !isOpen)}
    >
      {!isMobileMenuActive ? (
        <>
          <Image alt="menu" src={MobileMenuIcon} className="mobile" />
          <Image alt="menu" src={TabMenuIcon} className="tab" />
        </>
      ) : (
        <Box
          sx={{
            position: "absolute",
            color: "#ffffff",
            height: "36px",
            width: "36px",
            backgroundColor: "#000",
            borderRadius: "8px",
            border: "1px solid #25333C",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img className="close-icon" src={Close} alt="close" />
          {/* <img src={Close} alt="close" /> */}
        </Box>
      )}
    </div>
  );
};

interface IUserProfile {
  onLogout: () => void;
  referralCode?: string;
  referrals?: number;
  // user?: User;
}

const UserProfile = ({
  onLogout,
  referralCode,
  referrals,
}: // user,
IUserProfile) => {
  const [isShown, setIsShown] = useState(false);
  const menuProfileRef = useRef(null);
  const closeComponent = () => setIsShown(false);

  useMouseLeaveAlerter(menuProfileRef, closeComponent, true);

  function useMouseLeaveAlerter(ref: any, onLeave: any, watchParent = false) {
    useEffect(() => {
      const targetElement = watchParent ? ref.current?.parentNode : ref.current;

      const handleMouseLeave = () => {
        onLeave();
      };

      if (targetElement) {
        targetElement.addEventListener("mouseleave", handleMouseLeave);
      }

      return () => {
        if (targetElement) {
          targetElement.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }, [ref, onLeave, watchParent]);
  }

  const logout = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onLogout();
  };

  // const copyReferralCode = useCallback(() => {
  //   if (referralCode) {
  //     copyClipBoard(`${window.location.origin}/?referrer=${referralCode}`);
  //     setIsCopy(true);
  //   }
  // }, [referralCode]);

  return (
    <div
      className="top-nav__user-profile"
      onMouseEnter={() => {
        // setIsCopy(false);
        setIsShown(true);
      }}
    >
      <div className="top-nav__user-profile--wrapper">
        {/* <Link href={`/profile/`}> */}
        <Image className="profile" src={Profile} alt="profile logo" />
        {/* </Link> */}
        <Image className="arrow" src={DownArrow} alt="down arrow" />
      </div>

      {isShown && (
        <div className="top-nav__profile-menu-wrapper" ref={menuProfileRef}>
          <div className="top-nav__profile-menu">
            {/* <Link
              className="top-nav__profile-menu--item divider"
              href={`/profile/`}
            >
              <div className="content">
                <Image
                  alt="menu"
                  width={0}
                  height={0}
                  sizes="100vw"
                  src={ProfileIcon}
                  disablePlaceholder
                />
                My Profile
              </div>
            </Link> */}
            <div
              className="top-nav__profile-menu--logout"
              onClick={(e) => logout(e)}
            >
              <div className="content">
                <Image className="arrow" src={SignOut} alt="down arrow" />
                Log Out
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const MainMobileMenu = ({
  setIsSubMenuOpen,
  isAuthenticated,
  // user,
  setIsMobileMenuActive,
}: {
  setIsSubMenuOpen: (open: boolean) => void;
  isAuthenticated: boolean;
  // user?: User;
  setIsMobileMenuActive: (arg: boolean) => void;
}) => {
  // const { address, chain } = useAppSelector((state) => state.userLoginInfo);

  // const router = useRouter();
  const onMenuClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (e.currentTarget.href) {
      // await router.push(e.currentTarget.href);
    }
    setIsMobileMenuActive(false);
  };

  return (
    <div className="top-nav__mobile-menu--items">
      {MAIN_MENU_ITEMS.map(
        (
          {
            title,
            isActive,
            iconActive,
            isCurrentPage,
            link,
            isSubMenu,
            chains,
          },
          index
        ) => {
          if (!isAuthenticated) return null;
          if (!isActive) return null;
          // if (chains && !chains.includes(chain)) return null;

          // if (link.includes('/profile/[slug]')) {
          //   link = link.replace('[slug]', address ?? 'unknown');
          // }

          // if (key === 'profile') {
          //   return (
          //     <Link href={link} key={index} className={`${isCurrentPage ? 'current-page' : ''}`} onClick={onMenuClick}>
          //       <div className="left">
          //         <Image alt="menu" width={0} height={0} sizes="100vw" src={iconActive} disablePlaceholder />
          //         {title}
          //       </div>
          //     </Link>
          //   );
          // }

          return (
            <Link
              href={link}
              key={index}
              className={`${isCurrentPage ? "current-page" : ""}`}
            >
              <div className="left">
                <Image alt="menu" src={iconActive} />
                {title}
              </div>
            </Link>
          );
        }
      )}
    </div>
  );
};

const Header = observer(() => {
  const theme = useTheme();

  const {
    accountStore: { account, connectWallet, disconnectWallet },
  } = useAppStore();
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useEffect(() => {
    isMobileMenuActive
      ? document.body.classList.add("open-menu")
      : document.body.classList.remove("open-menu");
  }, [isMobileMenuActive]);

  const _handleClick = () => {
    return !account ? connectWallet() : disconnectWallet();
  };
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <nav className={`header ${isMobileMenuActive && "open"}`}>
      <div className="header__content">
        <div className="header__left">
          <div className="header__left--logo">
            <a href={"/"}>
              <img src={Logo} alt="bidds" />
            </a>
          </div>
          <Box
            className="header__left--menu"
            display={{ xs: "none", lg: "flex" }}
          >
            <div className="header__left--menu--item">
              <a href={`${PAGE_LINKS.COLLECTIONS}`}>Collections</a>
            </div>
            <div className="header__left--menu--item">
              <a href={`${PAGE_LINKS.LAUNCHPAD}`}>Launchpad</a>
            </div>{" "}
            <div className="header__left--menu--item">
              <a href={`${PAGE_LINKS.DOMAIN}`}>Domains</a>
            </div>{" "}
            <div className="header__left--menu--item dex">
              <a href={`/`}>DEX</a>
            </div>
          </Box>
        </div>
        <div className="header__right">
          <Box
            className="header__right--item balances"
            display={{ xs: "none", md: "flex" }}
          >
            <WalletHeaderBalances />
          </Box>

          <Box className="header__right--item-connect">
            {!account ? (
              // <Button
              //   className="btn-icon"
              //   color="primary"
              //   variant="contained"
              //   onClick={_handleClick}
              // >
              //   <img src={LoginIcon} alt="wallet icon" />
              //   {!isXsScreen && "Login"}
              // </Button>
              <Box className="btn-icon" onClick={_handleClick}>
                <img src={LoginIcon} alt="wallet icon" />
                {!isXsScreen && "LogIn"}
              </Box>
            ) : (
              <Box display={{ xs: "none", md: "flex" }}>
                <UserProfile onLogout={disconnectWallet} />
              </Box>
            )}
          </Box>

          <Box
            display={{ xs: "flex", lg: "none" }}
            className={`top-nav__mobile-screen  ${
              isMobileMenuActive && "active"
            }`}
          >
            <MobileMenu
              isMobileMenuActive={isMobileMenuActive}
              setIsMobileMenuActive={setIsMobileMenuActive}
              isAuthenticated={account ? true : false}
            />
          </Box>
        </div>
      </div>

      <div
        className={`top-nav__mobile-menu ${isMobileMenuActive && "mounted"}`}
      >
        <div className="mobile-menu-slide">
          <MainMobileMenu
            setIsSubMenuOpen={setIsSubMenuOpen}
            isAuthenticated={account ? true : false}
            // user={user}
            setIsMobileMenuActive={setIsMobileMenuActive}
          />
        </div>

        <div className="top-nav__mobile-menu--wrapper">
          {account && (
            <div className="top-nav__mobile-menu--balances">
              <div className="top-nav__mobile-menu--balances title">
                Balances
              </div>
              <div className="top-nav__mobile-menu--balances quotes">
                <WalletHeaderBalances />
              </div>
            </div>
          )}

          <div className="top-nav__mobile-menu--logout">
            {account ? (
              // <Button variant={"secondary"} onClick={() => onLogout()}>
              //   <img src={LogOutIcon} /> Log Out
              // </Button>
              <LoadingButton
                variant="onxrpPrimary"
                size="large"
                fullWidth
                sx={{
                  py: "10px",
                  // marginTop: 3,
                  marginBottom: 1,
                }}
                onClick={_handleClick}
              >
                Log Out
              </LoadingButton>
            ) : (
              // <Button
              //   variant={"secondary"}
              //   onClick={() => openOrClosedWalletModal(true)}
              // >
              //   <img src={LoginIcon} />
              //   Log In
              // </Button>
              <LoadingButton
                variant="onxrpPrimary"
                size="large"
                fullWidth
                sx={{
                  py: "10px",
                  // marginTop: 3,
                  marginBottom: 1,
                }}
                onClick={_handleClick}
              >
                Log In
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
});

export default Header;
